<template>
  <div class="login">
    <div class="login__container">
      <form class="login__form" action="#" method="post">
        <span class="login__caption">
          Войдите в свою учетную запись
        </span>
        <span class="login__error" v-show="hasError" v-html="error"></span>
        <div class="login__input-group">
          <input
              class="login__input"
              type="text"
              placeholder="Логин"
              v-model="login"
              :class="{ 'login__input_error' : hasError}"
          >
          <input
              class="login__input"
              type="password"
              placeholder="Пароль"
              v-model="password"
              :class="{ 'login__input_error' : hasError}"
          >
        </div>
        <app-button class="login__button" button-style="button_action" is-submit @click.prevent="signIn">
          Войти
        </app-button>
        <router-link class="login__link" :to="{ name: 'PasswordForgot' }">
          Восстановить доступ
        </router-link>
      </form>
      <footer class="login__footer">
        &#169; 2022-{{ new Date().getFullYear() }} ГК “Триумф”
      </footer>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'
import { isError } from "@/utils/request"

export default {
  name: 'Login',
  components: {
    AppButton
  },
  data() {
    return {
      login: '',
      password: '',
      error: '',
      hasError: false
    }
  },
  methods: {
    async signIn() {
      this.error = ''
      this.hasError = false

      const response = await this.$store.dispatch('user/signIn', {
        login: this.login,
        password: this.password
      })

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              this.error += `${error}<br>`
            }
          }
        } else {
          this.error = response.data.errors.title
        }

        this.hasError = true

        return
      }

      this.$router.push({ name: 'Profile' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.login {
  min-height: 100vh;
  padding: 50px 20px;
}

.login__form {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #eaedf4;
  border-radius: $border-radius;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
}

.login__caption {
  display: inline-block;
  width: 100%;
  margin-bottom: 22px;
  font-size: 14px;
  text-align: center;
}

.login__error {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  color: #d31a1a;
}

.login__input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  padding-left: 20px;
  font-size: 13px;
  background-color: #fcfdfe;
  border: 1px solid #eaedf4;
  border-radius: $border-radius;

  &:last-child {
    margin-bottom: 20px;
  }

  &:focus {
    outline: none;
  }

  &_error {
    border: 1px solid #d31a1a;
  }
}

.login__button {
  width: 100%;
  margin-bottom: 20px;
}

.login__link {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #555;
}

.login__footer {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #555;
}

@media screen and (min-width: 576px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .login__container {
    width: 440px;
  }

  .login__form {
    margin-bottom: 23px;
    padding: 54px 60px;
  }

  .login__caption {
    font-size: 17px;
  }

  .login__input {
    margin-bottom: 30px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .login__button {
    width: 100%;
    margin-bottom: 23px;
  }

  .login__link {
    font-size: 16px;
  }

  .login__footer {
    font-size: 16px;
  }
}
</style>